import React from "react";
import { LayoutDefault } from "../components/LayoutDefault";
import { WhoWeAre } from "../components/WhoWeAre";

export default ({ location }) => {
  return (
    <LayoutDefault>
      <WhoWeAre />
    </LayoutDefault>
  );
};
